import Vue from 'vue'

const MessageBoxOk = {
    async show(message, title, options) {
        const vm = new Vue()
        const h = vm.$createElement
        const hMessage = h('div', { domProps: { innerHTML: message } })
        return vm.$bvModal.msgBoxOk(
            [hMessage],
            {
                title,
                size: 'sm',
                autofocus:true,
                okVariant: options?.okVariant || 'primary',
                okTitle: options?.okTitle || 'Ok',
                hideHeaderClose: false,
                centered: true,
            },
        )
    },
}
Vue.prototype.$messageBoxOk = MessageBoxOk
window.MessageBoxOk = MessageBoxOk
export default MessageBoxOk
