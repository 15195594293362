<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <Loader :visible="loading"/>
    <hotkeys :shortcuts="['H']" @triggered="showHelpModal"/>
    <component :is="layout">
      <router-view/>
    </component>
    <b-modal
      id="modal-xl"
      ref="update_modal"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      hide-footer
      centered
      size="sm"
      title="Nova versão da plataforma"
    >
      <b-row>
        <b-col md="2">

          <feather-icon
            icon="InfoIcon"
            size="50"
          />
        </b-col>
        <b-col md="10">
          <h4>Antes de continuar, atualize para uma melhor experiência.</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          style="text-align: center"
          class="full-width"
        >
          <b-button
            variant="outline-primary"
            class="btn-icon mr-2"
            @click="update"
          >
            <feather-icon
              icon="ToolIcon"
              class="mr-50"
            />
            Atualizar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <help-modal :show="showHelp" @close="closeModalHelp"/>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import {$themeColors, $themeBreakpoints, $themeConfig} from '@themeConfig'
import {mapActions, mapState} from 'vuex'
import {ref, watch} from 'vue'
import Loader from '@/components/i2/http/loader/Loader.vue'
import useAppConfig from '@core/app-config/useAppConfig'

import {useWindowSize, useCssVar} from '@vueuse/core'

import store from '@/store'
import HelpModal from "@/components/i2/Help/HelpModal.vue";

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    HelpModal,

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    Loader,

  },
  data: () => ({
    registration: null,
    isRefresh: false,
    refreshing: false,
    unsubscribe: null,
    logged: false,
  }),
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapState('auth', ['isLogged']),
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    ...mapState('loader', ['loading']),
  },
  created() {
    document.addEventListener('serviceWorkerUpdateEvent', this.appUpdateUI, {once: true})
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }


    // Set RTL
    const {isRTL} = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  async mounted() {

    this.installClarity()
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'auth/LOGIN') {
        window?.$chatwoot?.toggleBubbleVisibility("show")
        this.logged = state.auth.isLogged
      }
    })
    let token = window.localStorage.getItem('accessToken')
    if (!token) {
      return
    }
    if (window.location.pathname === '/pdv' || process.env.VUE_APP_ENV === 'development') {
      return;
    }
    let user = await this.me()
    let BASE_URL = "https://atendimento.eloja360.digital"
    let g = document.createElement('script')

    if (!window.$chatwoot && this.logged) {
      window.chatwootSettings = {"position": "left", "type": "standard", "launcherTitle": "Fale conosco no chat"}
      let s = document.getElementsByTagName("script")[0]
      g.src = BASE_URL + "/packs/js/sdk.js"
      g.defer = true
      g.async = true
      s.parentNode.insertBefore(g, s)
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: 'am9fJX8NwPkyrooWxS2XPTbf',
          baseUrl: BASE_URL,
          position: 'left'
        })
      }

      window.addEventListener("chatwoot:ready", function () {

        window.$chatwoot.setCustomAttributes({
          loja: user?.store?.name,
        })
        window.$chatwoot.setUser(user.uuid, {
          email: user.email,
          name: user.name,
          company_name: user?.store?.name,

        })
      })
      if (this.logged) {
        window?.$chatwoot?.toggleBubbleVisibility("show")
      }
    }
  },
  beforeDestroy() {
    window?.$chatwoot?.reset()
    window?.$chatwoot?.toggleBubbleVisibility("hide")
    this.unsubscribe()
  },
  methods: {
    ...mapActions('auth', ['me']),
    appUpdateUI(e) {
      const url = e.detail?.waiting?.scriptURL || ''
      this.registration = e.detail
      if (url.includes('OneSignal')) {
        this.registration.waiting.postMessage({type: 'SKIP_WAITING'})
        this.isRefresh = false
        return
      }

      this.registration = e.detail
      this.isRefresh = true
      this.$refs.update_modal.show()
    },
    update() {
      this.isRefresh = false
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({type: 'SKIP_WAITING'})
      }
    },
    installClarity() {
      if (process.env.VUE_APP_ENV !== 'production') {
        return
      }
      const script = document.createElement('script');
      script.onload = () => {
        (function (c, l, a, r, i, t, y) {
          c[a] = c[a] || function () {
            (c[a].q = c[a].q || []).push(arguments)
          };
          t = l.createElement(r);
          t.async = 1;
          t.src = "https://www.clarity.ms/tag/" + i;
          y = l.getElementsByTagName(r)[0];
          y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "qtr1l58x03");
      };
      document.head.appendChild(script);
    }

  },

  setup() {

    const showHelp = ref(false)

    const showHelpModal = () => {
      if (!showHelp.value) {
        showHelp.value = true
      }
    }

    const closeModalHelp = () => {
      showHelp.value = false
    }
    const {skin, skinClasses} = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const {width: windowWidth} = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      showHelpModal,
      closeModalHelp,
      showHelp
    }
  },
}
</script>
