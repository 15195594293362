const pdv = [
    {
        path: '/pdv',
        name: 'pdv',
        component: () => import('@/views/PDV.vue'),
        meta: {
            layout: 'full',
            public: true,
            env:['production', 'development'],
        },
    },
]

export default pdv
